// const host = "http://localhost:3000"
const host = process.env.VUE_APP_API_URL ||  "https://www.bv-design.cn/api/v2"

export function upload(file) {
  const data = new FormData()
  data.set('file',file)
  return axios.post(host + '/upload',data,{
    header:{
      'Content-Type':'mulipart/form-data'
    }
  })
}

export function download (fileName) {
  return axios.get(host + "/download/" + fileName)
}


export function getImgUrl(file) {
  return host + "/download/" + file.name;
}

export function getProjects() {
  return axios.get(host + "/select/project")
}
export function getNews() {
  return axios.get(host + "/select/news")
}
export function getJob() {
  return axios.get(host + "/select/job")
}
export function getPartner() {
  return axios.get(host + "/select/partner")
}
export function getCommon() {
  return axios.get(host + "/select/common")
}

export function insertProjects(data) {
  return axios.post(host + "/insert/project",data)
}
export function insertNews(data) {
  return axios.post(host + "/insert/news",data)
}
export function insertJob(data) {
  return axios.post(host + "/insert/job",data)
}
export function insertPartner(data) {
  return axios.post(host + "/insert/partner",data)
}

export function updateProjects(data) {
  return axios.post(host + "/update/project",data)
}
export function updatetNews(data) {
  return axios.post(host + "/update/news",data)
}
export function updateJob(data) {
  return axios.post(host + "/update/job",data)
}
export function updatePartner(data) {
  return axios.post(host + "/update/partner",data)

}
export function updateCommon(data) {
  return axios.post(host + "/update/common",data)
}
export function deleteProjects(data) {
  return axios.post(host + "/delete/project",data)
}
export function deleteNews(data) {
  return axios.post(host + "/delete/news",data)
}
export function deleteJob(data) {
  return axios.post(host + "/delete/job",data)
}
export function deletePartner(data) {
  return axios.post(host + "/delete/partner",data)
}

export function getImages(data) {
  return axios.get(host + "/page/images",{params:data})
}

export function getImageType(){
  return axios.get(host + '/select/imageType')
}
